$(document).ready(function() {
    $('#get-directions-form').submit(function(e){
        $.ajax({
            type: "POST",
            url: "/get-directions",
            headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
            data: {
                address : $('input[name=address]').val(),
                language : $('input[name=language]').val()
            },
            dataType: 'json'
        }).done(function(data) {
        	if (data.success) {
	            legs = data.data;
	            //console.log(addresses);
	            var index;
	            steps = '';
	            polys = new Array();
	            if (legs) {
	                for (index = 0; index < legs.length; ++index) {
	                    console.log(legs[index].html_instructions);
	                    steps +=
	                    		'<li class="direction_li">'+
	                    			'<div class="div_direction_text">' +
	                    				legs[index].html_instructions +
	                    			'</div>'+
	                    			'<div class="div_direction_distance">' +
	                    				legs[index].distance.text + ' (' + legs[index].duration.text + ')'+
	                    			'</div>'+
                				'</li>';
                		polys[index] = legs[index].polyline.points;
	                }

	                if (steps) {
	                	$('#directions').html('<ul>'+steps+'</ul>');
	                }

	                initialize(polys);
	            }
	        } else {
	            alert('Addresses not found.');
	        }
        });
        e.preventDefault();
    });

	$('#headingOne').click(function(){
		$('#szallas_foglalas_tartalom').slideToggle();
	});

	$('#bejelentkezo_form .button').click(function(){
		szallas_igeny = $('#szallast_szeretne').val() * 1;
		if (szallas_igeny == 1) {
			$('#szallast_szeretne').val(0);
		} else {
			$('#szallast_szeretne').val(1);
		}
	});
});

function initialize(polys) {
	var myLatlng = new google.maps.LatLng(46.789142, 17.187797);
    var myOptions = {
        zoom: 8,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    var map = new google.maps.Map(document.getElementById("google_map"), myOptions);
        
    for (index = 0; index < polys.length; ++index) {
	    var decodedPath = google.maps.geometry.encoding.decodePath(polys[index]);
	    var decodedLevels = decodeLevels("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB");

	    var setRegion = new google.maps.Polyline({
	        path: decodedPath,
	        levels: decodedLevels,
	        strokeColor: "#d3a836",
	        strokeOpacity: 1.0,
	        strokeWeight: 2,
	        map: map
	    });
	}
}

function decodeLevels(encodedLevelsString) {
    var decodedLevels = [];

    for (var i = 0; i < encodedLevelsString.length; ++i) {
        var level = encodedLevelsString.charCodeAt(i) - 63;
        decodedLevels.push(level);
    }
    return decodedLevels;
}
